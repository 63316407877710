import {SET_LOGIN_STATE, SET_HEADER_VISIBLE,SET_USER} from './actionTypes';

export const setLoginState = payload => {
    return {
        type: SET_LOGIN_STATE,
        payload
    }
};

export const setHeaderVisible = payload => {
    return {
        type: SET_HEADER_VISIBLE,
        payload
    }
};

export const setUser = payload => {
    return {
        type: SET_USER,
        payload
    }
};