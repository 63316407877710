import React, { Component } from "react";
import AppConstants from "../../utils/AppConstants";
import AppviewModel from "../../utils/AppViewModel";
import { getUuid } from "../../utils/Helpers";
import { connect } from "react-redux";
import { setHeaderVisible } from "../../redux/reducers/loginStateReducer/actions";
import "./style.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
    };
  }

  componentDidMount() {
    this.props.setHeaderVisible(false);
    this.getCountries();
  }
  componentWillUnmount() {
    this.props.setHeaderVisible(true);
  }

  getCountries = () => {
    var uuid = getUuid();
    AppviewModel.sendApiCall(
      "/master/country?app_type=" + AppConstants.appType + "&unique_id=" + uuid,
      {},
      "GET",
      (response) => {
        console.log(response);
        AppviewModel.countries = response.data;
      },
      (error) => {
        console.log(error);
      }
    );
  };

  authenticate = () => {
    var uuid = localStorage.getItem("uuid");
    var payload = {
      unique_id: uuid,
      app_type: AppConstants.appType,
      country_code: AppviewModel.selectedCountry,
      mobile: this.state.mobile,
    };
    AppviewModel.sendApiCall(
      "/login-signup/login",
      payload,
      null,
      (response) => {
        switch (response.status) {
          case 1:
            localStorage.setItem("temp_mobile_number", this.state.mobile);
            this.props.history.replace("verification");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  render() {
    return (
      <section className="full-bg-img login-style-bg _fix_bg">
        <div className="middle-form-outer">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="middle-form-inner">
                  <div className="verification-main">
                    <div className="top-logo">
                      <img src="img/verification-user.png" alt="img" />
                    </div>
                    <div className="top-info">
                      <h4>Enter Mobile Number</h4>
                      <p>
                        Enter your 10 - digit mobile number to receive the
                        verification code
                      </p>
                    </div>
                    <form>
                      <div className="form-group">
                        <input
                          type="text"
                          value={this.state.mobile}
                          onChange={(e) =>
                            this.setState({ mobile: e.target.value })
                          }
                          className="form-control"
                          placeholder="+91 Mobile number"
                        />
                        <FontAwesomeIcon icon={faPhoneAlt} />
                      </div>
                      <div
                        className="submit-btn"
                        onClick={() => this.authenticate()}
                      >
                        <p className="button-60000f">GET VERIFICATION CODE</p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHeaderVisible: (data) => {
      dispatch(setHeaderVisible(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
