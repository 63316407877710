import React, { Component } from "react";
import Header from "./screens/Header";
import Footer from "./screens/Footer";
import Routes from "./routes";
import AppviewModel from "./utils/AppViewModel";
import { createUuid, getUuid } from "./utils/Helpers";
import AppConstants from "./utils/AppConstants";
import { connect } from "react-redux";
import { setHeaderVisible, setLoginState } from "./redux/reducers/loginStateReducer/actions";
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
  componentDidMount() {
    this.checkUuid();
    this.props.setHeaderVisible(true);
  }

  checkUuid = async () => {
    var id = localStorage.getItem("uuid");
    if (id) {
      this.initializeApp();
    } else {
      var uuid = createUuid();
      localStorage.setItem("uuid", uuid);
      this.initializeApp();
    }
  };

  initializeApp = () => {
    var token = localStorage.getItem("token");
    var payload = {
      unique_id: getUuid(),
      fcm_id: "fcmid",
      app_type: AppConstants.appType,
      token: token,
      app_version: AppConstants.appVersion,
      extra_info: null,
    };
    AppviewModel.sendApiCall(
      "/login-signup/app-status",
      payload,
      null,
      (response) => {
        this.getConf(response);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  getConf = (response) => {
    var conf = localStorage.getItem("landingpage_configurations");
    conf = JSON.parse(conf);
    if (conf && response.landing_page_version === conf.version) {
      AppviewModel.getLandingPage = false;
    } else {
      AppviewModel.getLandingPage = true;
    }
    switch (response.status) {
      case 1:
        this.props.setLoginState(true);
        break;
      case 2:
        localStorage.removeItem("token");
        this.props.setLoginState(false);
        break;
      case 3:
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <React.Fragment>
        <NotificationContainer/>
        <Header />
        <Routes />
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateTpProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginState: (data) => {
      dispatch(setLoginState(data));
    },
    setHeaderVisible:(data)=>{dispatch(setHeaderVisible(data))}
  };
};

export default connect(mapStateTpProps, mapDispatchToProps)(App);
