import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  setLoginState,
  setUser,
} from "../../redux/reducers/loginStateReducer/actions";
import AppConstants from "../../utils/AppConstants";
import appviewModel from "../../utils/AppViewModel";
import "./style.scss";

class SubCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ isLoading: true });
    var payload = {
      page_size: 20,
      page_index: 0,
      category_id: this.props.match.params.id,
    };
    appviewModel.sendApiCall(
      "/subcategories",
      payload,
      null,
      (response) => {
        console.log(response);
        this.setState({ data: response.data, isLoading: false });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  render() {
    if (this.state.isLoading) return null;
    return (
      <div className="subcategory_screen">
        <section className="sub-category-outer">
          <div className="container">
            <div className="row">
              {this.state.data.map((item) => {
                return (
                  <div className="col-md-4">
                    <div className="sub-category-list">
                      <div className="sub-cat-img">
                        <Link to={"/styles/"+item.id}>
                          <img src={AppConstants.baseUrl + item.image} alt="" className="image"/>
                        </Link>
                      </div>
                      <div className="sub-cat-title">
                        <Link to="/styles/1">{item.title}</Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateTpProps = (state) => {
  return {
    login_state: state.login_state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginState: (data) => {
      dispatch(setLoginState(data));
    },
    setUser: (data) => {
      dispatch(setUser(data));
    },
  };
};

export default connect(mapStateTpProps, mapDispatchToProps)(SubCategories);
