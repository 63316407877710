import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';

var store =  initialState => {
    initialState =
        JSON.parse(window.localStorage.getItem('state')) || initialState;
    const middleware = [thunk];

    const store = createStore(
        rootReducer,
        { ...initialState },
        compose(
            applyMiddleware(...middleware)
            /* window._REDUX_DEVTOOLS_EXTENSION_ &&
              window._REDUX_DEVTOOLS_EXTENSION_() */
        )
    );

    store.subscribe(() => {
        const state = store.getState();
        const persist = {
           login_state:state.login_state 
        };

        window.localStorage.setItem('state', JSON.stringify(persist));
    });

    return store;
};

export default store;