import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setLoginState,
  setUser,
} from "../../redux/reducers/loginStateReducer/actions";
import "./style.scss";

class Colors extends Component {

  render() {
    return (
      <div className="style_screen">
          <section class="product-color-select">
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <div class="color-heading">
               <h4>Select among 20+ Color :</h4>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-sm-12">
            <div class="color-slider">
               <div class="color-list">
                  <a href="#">
                     <div class="img-block">
                        <img src="img/categories-img.jpg" alt="Blazer"/>
                     </div>
                     <div class="color-title"><i class="fas fa-heart"></i> Red</div>
                  </a>
               </div>
               <div class="color-list">
                  <a href="#">
                     <div class="img-block">
                        <img src="img/Indo-Western-Kurta.jpg" alt="Blazer"/>
                     </div>
                     <div class="color-title"><i class="fas fa-heart"></i> Yellow</div>
                  </a>
               </div>
               <div class="color-list">
                  <a href="#">
                     <div class="img-block">
                        <img src="img/categories-img.jpg" alt="Blazer"/>
                     </div>
                     <div class="color-title"><i class="fas fa-heart"></i> Red</div>
                  </a>
               </div>
               <div class="color-list">
                  <a href="#">
                     <div class="img-block">
                        <img src="img/Indo-Western-Kurta.jpg" alt="Blazer"/>
                     </div>
                     <div class="color-title"><i class="fas fa-heart"></i> Yellow</div>
                  </a>
               </div>
               <div class="color-list">
                  <a href="#">
                     <div class="img-block">
                        <img src="img/categories-img.jpg" alt="Blazer"/>
                     </div>
                     <div class="color-title"><i class="fas fa-heart"></i> Red</div>
                  </a>
               </div>
               <div class="color-list">
                  <a href="#">
                     <div class="img-block">
                        <img src="img/Indo-Western-Kurta.jpg" alt="Blazer"/>
                     </div>
                     <div class="color-title"><i class="fas fa-heart"></i> Yellow</div>
                  </a>
               </div>
               <div class="color-list">
                  <a href="#">
                     <div class="img-block">
                        <img src="img/categories-img.jpg" alt="Blazer"/>
                     </div>
                     <div class="color-title"><i class="fas fa-heart"></i> Red</div>
                  </a>
               </div>
               <div class="color-list">
                  <a href="#">
                     <div class="img-block">
                        <img src="img/Indo-Western-Kurta.jpg" alt="Blazer"/>
                     </div>
                     <div class="color-title"><i class="fas fa-heart"></i> Yellow</div>
                  </a>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
      </div>
    );
  }
}

const mapStateTpProps = (state) => {
  return {
    login_state: state.login_state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginState: (data) => {
      dispatch(setLoginState(data));
    },
    setUser: (data) => {
      dispatch(setUser(data));
    },
  };
};

export default connect(mapStateTpProps, mapDispatchToProps)(Colors);
