import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import "./style.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"

class Footer extends Component {
  render() {
    if(this.props.location.pathname ==='/login'||this.props.location.pathname ==='/verification'||this.props.location.pathname ==='/addinfo') return null;
    return (
      <footer className="footer-wrapper-main">
        <div className="footer-main">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="footer-menu-title">QUICK LINKS</div>
                <div className="footer-menu-link">
                  <ul>
                    <li>
                      <a href="/#">About Us</a>
                    </li>
                    <li>
                      <a href="/#">Contact Us</a>
                    </li>
                    <li>
                      <a href="/#">Terms of Use</a>
                    </li>
                    <li>
                      <a href="/#">Privacy policies</a>
                    </li>
                    <li>
                      <a href="/#">FAQ's</a>
                    </li>
                    <li>
                      <a href="/#">Blogs</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="app-download-footer">
                  <h3>DOWNLOAD THE APP</h3>
                  <div className="store-btn">
                    <div className="flex social-btns">
                      <a className="app-btn flex" href="/#">
                        <img src="img/google-play.png" alt="img" />
                      </a>
                      <a className="app-btn flex" href="/#">
                        <img src="img/app-store.png" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="footer-logo">
                  <a href="/#">
                    <img src="img/singla-logo.png" alt="single logo" />
                  </a>
                </div>
                <div className="footer-social">
                  <ul>
                    <li>
                      <a href="/#">
                      <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                      <FontAwesomeIcon icon={faFacebook} />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                      <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="copyright-text">
                  <p>
                    Copyright ©2020 Singla Apparels Pvt Ltd. All rights reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withRouter(Footer);
