import React, { Component } from "react";
import { getUuid } from "../../utils/Helpers";
import { connect } from "react-redux";
import {
  setHeaderVisible,
  setLoginState,
  setUser,
} from "../../redux/reducers/loginStateReducer/actions";
import "./style.scss";
import AppConstants from "../../utils/AppConstants";
import appviewModel from "../../utils/AppViewModel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faMapMarker, faUser } from '@fortawesome/free-solid-svg-icons';

class AddAccountInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shopName: "",
      fname: "",
      pincode: "",
      ReceiveUpdatesOnWhatsapp: false,
    };
  }

  componentDidMount() {
    this.props.setHeaderVisible(false);
  }

  componentWillUnmount() {
    this.props.setHeaderVisible(true);
  }

  setLoggedInUser = (response) => {
    this.props.setLoginState(true);
    this.props.setUser(response.user_info);
    this.props.history.replace("/");
  };

  save = () => {
    var payload = {
      unique_id: getUuid(),
      app_type: AppConstants.appType,
      shop_name: this.state.shopName,
      fname: this.state.fname,
      pincode: this.state.pincode,
      ReceiveUpdatesOnWhatsapp: this.state.ReceiveUpdatesOnWhatsapp,
    };
    appviewModel.sendApiCall(
      "/login-signup/registration",
      payload,
      null,
      (response) => {
        this.setLoggedInUser(response);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  handleInputChange = (event) => {
    this.setState({
      ReceiveUpdatesOnWhatsapp: event.target.checked,
    });
  };

  render() {
    return (
      <section className="full-bg-img addinfo-style-bg _fix_bg">
        <div className="middle-form-outer">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="middle-form-inner">
                  <div className="verification-main">
                    <div className="top-logo">
                      <img src="img/account-information.png" alt="img" />
                    </div>
                    <div className="top-info">
                      <h4>Enter Account Information</h4>
                      <p>Register your business on Singla Apparels</p>
                    </div>
                    <form>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Your Name"
                          value={this.state.fname}
                          onChange={(e) =>
                            this.setState({ fname: e.target.value })
                          }
                        />
                        <FontAwesomeIcon icon={faUser} />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Business or Shop Name"
                          value={this.state.shopName}
                          onChange={(e) =>
                            this.setState({ shopName: e.target.value })
                          }
                        />
                        <FontAwesomeIcon icon={faBriefcase} />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Pincode (Example : 560087)"
                          value={this.state.pincode}
                          onChange={(e) =>
                            this.setState({ pincode: e.target.value })
                          }
                        />
                        <FontAwesomeIcon icon={faMapMarker} />
                      </div>
                      <div className="form-group">
                        <label className="container-checkbox">
                          {" "}
                          Receive order and account related updates on WhatsApp
                          <input
                            type="checkbox"
                            checked={this.state.ReceiveUpdatesOnWhatsapp}
                            onChange={(e) => this.handleInputChange(e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="submit-btn" onClick={() => this.save()}>
                        <p className="button-60000f">
                          PROCEED
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHeaderVisible: (data) => {
      dispatch(setHeaderVisible(data));
    },
    setLoginState: (data) => {
      dispatch(setLoginState(data));
    },
    setUser: (data) => {
      dispatch(setUser(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAccountInfo);
