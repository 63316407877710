import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AppConstants from "../../utils/AppConstants";
import "./style.scss";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
  comp: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default class ItemCarousel extends Component {
  render() {
    return (
      <Carousel responsive={responsive}>
        {this.props.data.map((item,index) => {
          return (
            <div className="slide-item" onClick={() => this.props.onClick(item.id)} key={index}>
              <div className="image-section">
                <img src={AppConstants.baseUrl + item.image} alt="category" />
              </div>
              <p className="label">{item.title}</p>
            </div>
          );
        })}
      </Carousel>
    );
  }
}
