import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./style.scss";
import ItemCarousel from "../../components/ItemCarousel";
import AppviewModel from "../../utils/AppViewModel";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      abc:null
    };
  }

  componentDidMount() {
    if (this.props.login_state.isLogin) {
      this.getCategories();
    }
  }

  getCategories = () => {
    AppviewModel.sendApiCall(
      "/category/get",
      null,
      "GET",
      (response) => {
        console.log(response);
        this.setState({ categories: response.categories });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  openSubcategory=(id)=>{
    this.props.history.push('subcategories/'+id);
  }

  render() {
    return (
      <div>
        <section className="home_banner_wraper">
          <ul className="home-slider slider-main">
            <li className="slide">
              <div className="full-thumbnail">
                <img src="img/home-banner.jpg" alt="img" />
              </div>
              <div className="slide-caption">
                <div className="container">
                  <div className="slide-caption-wraper">
                    <div className="slide-caption-content">
                      <h1>A Wholesale marketplace for Men's apparels.</h1>
                      <h4>Ethnic Wears | Western wear | Acessories</h4>
                      <div className="banner-btn">
                        <Link className="btn zoominleft" to="/">
                          Source Now
                        </Link>
                      </div>
                    </div>
                    <div className="slide-caption-img">
                      <img src="img/phone-img.png" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </section>

        {this.props.login_state.isLogin && (
          <section className="category-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="singla-heading-witharrow">
                    <h4>Categories</h4>
                  </div>
                  <ItemCarousel data={this.state.categories} onClick={(id)=>this.openSubcategory(id)}/>
                </div>
              </div>
            </div>
          </section>
        )}

        <section className="quick-info-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="quick-info-outer">
                  <div className="quick-info-img">
                    <img src="img/wholesale-price.png" alt="img" />
                  </div>
                  <div className="quick-info-content">
                    <h5>
                      Unbeatable <br /> Wholesale price
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="quick-info-outer">
                  <div className="quick-info-img">
                    <img src="img/door-deliver.png" alt="img" />
                  </div>
                  <div className="quick-info-content">
                    <h5>
                      Door deliver <br /> across India
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="quick-info-outer">
                  <div className="quick-info-img">
                    <img src="img/styles.png" alt="img" />
                  </div>
                  <div className="quick-info-content">
                    <h5>
                      10,000+ <br /> Styles
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="quick-info-outer">
                  <div className="quick-info-img">
                    <img src="img/buy-now-pay-later.png" alt="img" />
                  </div>
                  <div className="quick-info-content">
                    <h5>
                      Buy now <br /> Pay later
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hm-works-main">
          <div className="both-corner-icon">
            <div className="corner-icon-left">
              <img src="img/corner-l-U.png" alt="Icon" />
            </div>
            <div className="corner-icon-right">
              <img src="img/corner-R-U.png" alt="Icon" />
            </div>
          </div>
          <div className="how-work-content">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="title-underline">
                    <h2>How it Works ?</h2>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-5">
                  <div className="work-main-img">
                    <img src="img/phone-img.png" alt="img" />
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="how-work-steps">
                    <ul>
                      <li>
                        <a href="/#">
                          <span className="steps-count">Step 1 :</span>
                          <div className="step-outer">
                            <div className="steps-info">
                              <span className="steps-icon">
                                <img src="img/icon-5.png" alt="img" />
                              </span>
                              <span className="steps-content">
                                <h5>Signup</h5>
                                <p>
                                  A simple two step signup process of less than
                                  a minute.
                                </p>
                              </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <span className="steps-count">Step 2 :</span>
                          <div className="step-outer">
                            <div className="steps-info">
                              <span className="steps-icon">
                                <img src="img/icon-6.png" alt="img" />
                              </span>
                              <span className="steps-content">
                                <h5>Complete KYC</h5>
                                <p>Upload either Udyog Aadhar | GST</p>
                              </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <span className="steps-count">Step 3 :</span>
                          <div className="step-outer">
                            <div className="steps-info">
                              <span className="steps-icon">
                                <img src="img/icon-7.png" alt="img" />
                              </span>
                              <span className="steps-content">
                                <h5>Explore products</h5>
                                <p>
                                  From range of 10,000+ high quality products
                                </p>
                              </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <span className="steps-count">Step 4 :</span>
                          <div className="step-outer">
                            <div className="steps-info">
                              <span className="steps-icon">
                                <img src="img/icon-8.png" alt="img" />
                              </span>
                              <span className="steps-content">
                                <h5>Shop at best price</h5>
                                <p>
                                  Unmatched factory price with up to 90%
                                  retailer margin
                                </p>
                              </span>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hm-download-app">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="download-title">
                    <h3>Download the App</h3>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="store-btn">
                    <div className="flex">
                      <a className="app-btn flex" href="/#">
                        <img src="img/google-play.png" alt="img" />
                      </a>
                      <a className="app-btn flex" href="/#">
                        <img src="img/app-store.png" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fulfilled-products-main">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="normal-title">
                  <h2>Buy Singla Fulfilled products</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="fulfilled-outer">
                  <div className="fulfilled-icon">
                    <img src="img/icon-9.png" alt="img" />
                  </div>
                  <div className="fulfilled-title">
                    <h4>
                      Priority <br /> Onboarding
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="fulfilled-outer">
                  <div className="fulfilled-icon">
                    <img src="img/icon-10.png" alt="img" />
                  </div>
                  <div className="fulfilled-title">
                    <h4>
                      Quick <br /> Processing
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="fulfilled-outer">
                  <div className="fulfilled-icon">
                    <img src="img/icon-11.png" alt="img" />
                  </div>
                  <div className="fulfilled-title">
                    <h4>
                      Payment <br /> Protection
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="fulfilled-outer">
                  <div className="fulfilled-icon">
                    <img src="img/icon-12.png" alt="img" />
                  </div>
                  <div className="fulfilled-title">
                    <h4>
                      Door step <br /> Delivery
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="upload-kyc-button">
                  <a href="/#" className="button-60000f">
                    Upload KYC
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="customers-says-main">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title-underline">
                  <h2>
                    What our Customers <br /> are Saying
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="testimonial-slider">
                  <div className="client-testimonial">
                    <div className="client-image">
                      <img src="img/testmonial.png" alt="img" />
                    </div>
                    <div className="client-name">Mr. Harpreet Chadda</div>
                    <div className="client-company">
                      Express fashion Pvt.Ltd
                    </div>
                    <div className="testimonial-content">
                      <h5>Very good Service !</h5>
                      <p>
                        Amazing Quality products! Their way of handling the
                        contract was very good. It was very hazel free and
                        smooth transition of products. I would surely continue
                        to order products from Singla Apparels.
                      </p>
                    </div>
                  </div>
                  <div className="client-testimonial">
                    <div className="client-image">
                      <img src="img/testmonial.png" alt="img" />
                    </div>
                    <div className="client-name">Mr. Harpreet Chadda</div>
                    <div className="client-company">
                      Express fashion Pvt.Ltd
                    </div>
                    <div className="testimonial-content">
                      <h5>Very good Service !</h5>
                      <p>
                        Amazing Quality products! Their way of handling the
                        contract was very good. It was very hazel free and
                        smooth transition of products. I would surely continue
                        to order products from Singla Apparels.
                      </p>
                    </div>
                  </div>
                  <div className="client-testimonial">
                    <div className="client-image">
                      <img src="img/testmonial.png" alt="img" />
                    </div>
                    <div className="client-name">Mr. Harpreet Chadda</div>
                    <div className="client-company">
                      Express fashion Pvt.Ltd
                    </div>
                    <div className="testimonial-content">
                      <h5>Very good Service !</h5>
                      <p>
                        Amazing Quality products! Their way of handling the
                        contract was very good. It was very hazel free and
                        smooth transition of products. I would surely continue
                        to order products from Singla Apparels.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="get-app-main">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="get-app-img">
                  <img src="img/phone-img.png" alt="img" />
                </div>
              </div>
              <div className="col-md-7">
                <div className="get-mobile-link">
                  <h2>
                    Get the <img src="img/singla-app-link.png" alt="img" /> APP
                  </h2>
                  <p>Search for products and connect with us on the Go!</p>
                  <div className="send-link-wrap">
                    <form>
                      <div className="row">
                        <div className="col-sm-7">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="+ 91 Enter mobile Number"
                            />
                          </div>
                        </div>
                        <div className="col-sm-5">
                          <div className="send-link-btn">
                            <input
                              type="submit"
                              name=""
                              value="Send me the link"
                              className="btn btn-submit"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <p>
                    We will send you the link, open it on your phone to download
                    the App
                  </p>
                  <div className="app-store-link">
                    <div className="row">
                      <div className="col-6">
                        <div className="store-img-link">
                          <a href="/#">
                            <img src="img/google-play.png" alt="img" />
                          </a>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="store-img-link">
                          <a href="/#">
                            <img src="img/app-store.png" alt="img" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-lines">
            <div className="bottom-line-left">
              <img src="img/line-bg.png" alt="img" />
            </div>
          </div>
        </section>

        <section className="newsletter-main">
          <div className="both-corner-icon">
            <div className="corner-icon-left">
              <img src="img/corner-l-U.png" alt="Icon" />
            </div>
            <div className="corner-icon-right">
              <img src="img/corner-R-U.png" alt="Icon" />
            </div>
          </div>
          <div className="newsletter-content">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="newsleter-top">
                    <h4>JOIN THE SINGLA FAMILY</h4>
                    <p>Subscribe to our Newsletter for latest updates</p>
                    <div className="arrow-img">
                      <img src="img/line-L.png" alt="Icon" />
                      <img src="img/line-R.png" alt="Icon" />
                    </div>
                  </div>
                  <div className="newsletter-bottom">
                    <div className="newsletter-block">
                      <form>
                        <div className="newsletter-input">
                          <input
                            name="email"
                            type="email"
                            placeholder="Enter your email"
                          />
                        </div>
                        <div className="newsletter-button">
                          <button className="button-60000f" type="submit">
                            Join Now
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateTpProps = (state) => {
  return {
    login_state: state.login_state,
  };
};

export default connect(mapStateTpProps)(Home);
