import AppConstants from "./AppConstants";
import { NotificationManager } from "react-notifications";

var appviewModel =  {
  loggedInUser: null,
  countries: null,
  selectedCountry:'91',
  getLandingPage:true,
  sendApiCall: async (
    url,
    payload,
    method,
    successHandler,
    ErrorHandler,
    showAPIError
  ) => {
    method = method ? method : "POST";
    var headers = {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    };
    var token = localStorage.getItem("token");
    // if (token && method == "POST") {
    //   payload.token = token;
    // }
    if (token) {
      headers = {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Authorization: 'Bearer ' + token
      };
    }
    var callState = null;
    var body = [];
    for (var property in payload) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(payload[property]);
      body.push(encodedKey + "=" + encodedValue);
    }
    body = body.join("&");
    var requestBody = {
      method: method,
      headers: headers,
    };
    if (method === "POST") {
      requestBody.body = body;
    }
    fetch(AppConstants.baseUrl + url, requestBody)
      .then((response) => {
        callState = response.status;
        return response.json();
      })
      .then((responsejson) => {
        if (callState === 200 || callState === 201) {
          successHandler(responsejson);
        } else {
          ErrorHandler(responsejson);
          if (!showAPIError) {
            // alert(responsejson.msg);
            NotificationManager.error(responsejson.msg,'Server Error');
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ErrorHandler(error);
        alert("Something went wrong, while communicating with server.");
      });
  },
};

export default appviewModel;
