import React, { Component } from "react";
import { connect } from "react-redux";
import { setLoginState, setUser } from "../../redux/reducers/loginStateReducer/actions";
import "./style.scss";

class Profile extends Component {
    
  logout = () => {
      localStorage.removeItem('token');
      this.props.setLoginState(false);
      this.props.setUser(null);
      this.props.history.push('/');
  };

  render() {
    return (
      <div className="profile_screen">
        <button onClick={() => this.logout()}>Logout</button>
      </div>
    );
  }
}

const mapStateTpProps = (state) => {
  return {
    login_state: state.login_state,
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
      setLoginState: (data) => {
        dispatch(setLoginState(data));
      },
      setUser: (data) => {
        dispatch(setUser(data));
      }
    };
  };

export default connect(mapStateTpProps,mapDispatchToProps)(Profile);
