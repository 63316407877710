import React, { Component } from "react";
import { connect } from "react-redux";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      abc:null
    };
  }

  render() {
    return (
      <div>
          <section class="privacypolicy">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="title h2">Privacy Policy</h1>
                <p>Singla Apparels Private Limited, doing business as Singla Apparels ("Singla Apparels", "we",  "us", "our") having its registered office at 7246 Mahavir Gali, Gandhi Nagar, Delhi 110031, India operates <a href="singlaapparels.com">singlaapparels.com</a> (hereinafter referred to as “website”). </p>
                <p>Our Privacy Policy governs your visit to Singla Apparels, our mobile application (“the  Application”) or more generally, use any of our services (the "Services", which include the  Website and App) and explains how we collect, safeguard and disclose information that results  from your use of our website. This privacy policy applies to all information collected through  our Services (which, as described above, includes our Website and App), as well as, any related  services, sales, marketing or events. </p>
                <p>Our Terms and Conditions (“Terms”) that govern all use of our website and together with this  Privacy Policy, constitutes an agreement with us (“agreement”). Please read this Privacy Policy  carefully.<strong>If you do not agree with the terms of this Privacy Policy, kindly discontinue the  use of our Services immediately.</strong></p>
                <h4>DATA COLLECTION</h4>
                <p>Singla Apparels requires you to voluntarily provide us with certain personal data that we collect.  In this Policy, "Personal Data" refers to any information that on its own, or in combination with  other available information, can distinguish an individual. It may include “sensitive personal information or data” as defined under the Information Technology (Reasonable Security  Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011. We are  committed to protecting your privacy in accordance with the highest level of privacy regulation.</p>
                <p>When you create an account with Singla Apparels to utilize our Services, you express your interest  to obtain information about us, our products and services and to participate in the activities on  our services. The personal information that we collect depends on the context of your  interactions with us and the Services, the choices you make and the products and features you  use. All personal information that you provide to us must be true, complete and accurate, and  you must notify us of any changes to such personal information. It should be noted that by  accessing and/or registering on the website, you have represented and warranted that you are  either 18 years of age or using the website with the supervision of a parent or guardian. </p>
                <ul>
                    <li>                         
                        <p><strong>Personal Data:</strong> When you register with Singla Apparels, we collect certain personally  identifiable information such as: your name, phone number, email address and/or  mobile number, and a password and demographic information such as postal code,  preference and interests to secure the Platform and make improvements and to help  provide you customized services in an effective manner (for example: updates, content,  more relevant ads and opportunities) and to communicate with you.</p> 
                        <p>You have the right to “opt out” of receiving marketing communications, whether by  email or otherwise, at any time. You can do this by (i) clicking the unsubscribe link  displayed in any of the marketing emails you receive, (ii) emailing  <a href="mailto: care@singlaapparels.com">care@singlaapparels.com</a> to indicate you no longer wish to receive marketing  communications.</p>
                        <p>While registering with us, our Services provide you with the option to register through  a third-party application such as Facebook, Twitter, or any other social media platform.  Where you choose to do this, we will receive certain profile information about you from  your social media provider. The profile information we receive may vary depending on  the social media provider concerned, but will often include your name, email address,  friends list, profile picture as well as other information you choose to make public on  such third-party platforms. </p>
                        <p>Similarly, to improve your experience, we work with third-party partners to display  their video content on Youtube, Twitter, Facebook, Instagram or any other social media  platforms. When you watch or otherwise interact with content from these partners, they  may receive and process your personal data as described in their privacy policies.</p>
                        <p>We have not reviewed, and cannot review, all of the material, including computer  software, made available through the websites and webpages to which  singlaapparels.com links, and that link to singlaapparels.com. Please note that we do not  control, and are not responsible for, other uses of your personal information by your  third-party social media provider. We recommend that you review their privacy notice  to understand how they collect, use and share your personal information, and how you  can set your privacy preferences on their sites and apps.</p>
                    </li>
                    <li>                        
                        <p><strong>Financial Data:</strong> Any purchase made by you as part of our Service, requires us to collect  financial information, such as data related to your payment method; e.g debit/credit card numbers; card expiration date, CVV code; billing addresses; and other similar  information. </p>
                    </li>
                    <li>
                        <p><strong>Derivative Data:</strong> When you access the Application, our servers <strong>automatically</strong> collect  information such as your native actions that are integral to the Application and involve  interactions with the Application and other users via server log files. Log and usage  data is service-related, diagnostic, usage and performance information our servers  automatically collect when you access or use our Services and which we record in log  files. </p>
                        <p>Depending on how you interact with us, this log data may include your location, IP  address (or proxy server), Internet service provider and/or mobile carrier, device  information (device and application identification numbers), browser type, and settings  and information about your activity in the Services (such as the date/time stamps  associated with your usage, pages and files viewed, searches and other actions you take  such as which features you use), device event information (such as system activity,  error reports (sometimes called 'crash dumps') hardware settings, operating system and  system configuration information.</p>
                        <p>We use Log Data to operate our services and ensure their secure, reliable, and robust performance (e.g protect the security of accounts). You can opt out of allowing us to  collect Location information either by refusing access to the information or by disabling  your Location setting on your device. Note however, if you choose to opt out, you may  not be able to use certain aspects of the Services.</p>
                    </li>
                </ul>
                <h4>TRACKING COOKIE DATA</h4>
                <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device.</p>
                <p>We use cookies and similar tracking technologies to track the activity on our website and we hold certain information. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service</p>
                <p>We use certain cookies to make our site work, you can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service. </p>
                <h4>USE OF INFORMATION </h4>
                <p>When you register, you also agree to our User Agreement and this Privacy Policy, which  governs how we treat your information. We do not sell, distribute or lease your personal  information to third parties unless we have your permission or unless the disclosure of the  information is reasonably necessary to fulfill: </p>
                <ul>
                    <li>
                        <p><strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to  achieve our legitimate business interests.</p>
                    </li>
                    <li>
                        <p><strong>Performance of a Contract:</strong> Your personal information may be processed by us in  order to fulfill the terms of the contract in which we have entered as parties. </p>
                    </li>
                    <li>
                        <p><strong>Legal Obligations: </strong> We are legally required to disclose your personal information in  order to comply with applicable laws or in case of any requests made by the  Government. Moreover, if such information is required in any judicial proceeding,  court order, or legal process, such as in response to a court order or a subpoena  (including in response to public authorities to meet national security or law enforcement  requirements), we are bound by law to hand over the information to the concerned  authority.</p>
                    </li>
                    <li>
                        <p><strong>Vital Interests:</strong> Where we believe it is necessary to scrutinize, prevent, or take action  regarding potential infringement of our policies, situations involving suspected fraud, potential threats to the safety of any person and illegal activities, or as evidence in  litigation in which we are involved. </p>
                    </li>
                    <li>
                        <p><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or  during negotiations of, any merger, sale of company assets, financing, or acquisition of  all or a portion of our business to another company.</p>
                    </li>
                    <li>
                        <p><strong>Google Maps Platform APIs: </strong> Certain Google Maps Platform APIs (e.g., Google Maps  API, Place API) may require the processing of your personal information. We obtain  and store on your device ('cache') your location. You may terminate your consent  anytime by contacting us at the contact details provided at the end of this document.</p>
                    </li>
                    <li>
                        <p><strong>Vendors, Consultants and Other Third-Party Service Providers:</strong> We may share  your data with third-party vendors, service providers, contractors or agents who  perform services for us or on our behalf and require access to such information to do  that work. Examples include: payment processing, data analysis, email delivery,  hosting services, customer service and marketing efforts. We may allow selected third  parties to use tracking technology on the Services, which will enable them to collect  data on our behalf about how you interact with our Services over time. This information  may be used to, among other things, analyze and track data, determine the popularity  of certain content, pages or features, and better understand online activity. Unless  described in this notice, we do not share, sell, rent or trade any of your information with  third parties for their promotional purposes. </p>
                    </li>
                    <li>
                        <p><strong>Offer Wall:</strong> Our Apps may display a third-party hosted “offer wall.” Such an offer wall  allows third-party advertisers to offer virtual currency, gifts, or other items to users in  return for acceptance and completion of an advertisement offer. Such an offer wall may  appear in our mobile application and be displayed to you based on certain data, such as  your geographic area or demographic information. When you click on an offer wall,  you will leave our mobile application and will be directed to an external third-party  website.</p>
                        <p>A unique identifier, such as your user ID, will be shared with the offer wall provider in  order to prevent fraud and properly credit your account with the relevant reward. Please  note that we do not control third-party websites and have no responsibility in relation  to the content of such websites. The inclusion of a link towards a third-party website  does not imply an endorsement by us of such a website. Accordingly, we do not make  any warranty regarding such third-party websites and we will not be liable for any loss or damage caused by the use of such websites. In addition, when you access any third party website, please understand that your rights while accessing and using those  websites will be governed by the privacy notice and terms of service relating to the use  of those websites.</p>
                    </li>
                </ul>
                <h4>SHARING PERSONAL DATA </h4>
                <p>We do not sell, otherwise disclose, or share data we collect and hold about you, except as  described in this Privacy Policy. We only share and disclose your information with the  following categories of third parties. If we have processed your data based on your consent and  you wish to revoke your consent, please contact us using the contact details.</p>
                <ul>
                    <li>Government Entities</li>
                    <li>Social Networks </li>
                    <li>Retargeting Platforms </li>
                    <li>Sales & Marketing Tools</li>
                    <li>User Account Registration & Authentication Services</li>
                    <li>Website Hosting Service Providers</li>
                    <li>Affiliate Marketing Programs</li>
                    <li>Cloud Computing Services</li>
                    <li>Data Analytics Services</li>
                    <li>Data Storage Service Providers</li>
                    <li>Finance & Accounting Tools</li>
                    <li>Payment Processors</li>
                    <li>Performance Monitoring Tools</li>
                </ul>
                <p>If we have processed your data based on your consent and you wish to revoke your consent,  please contact us using the contact details.</p>
                <h4>INFORMATION SECURITY</h4>
                <p>We have implemented appropriate technical and organizational security measures designed to  protect the security of any personal information we process. While we strive to use all  commercially acceptable means to protect your information, despite our safeguards and efforts  to secure your information, we cannot guarantee absolute security as no electronic transmission  over the Internet or information storage technology can be guaranteed to be 100% secure. Thus,  we cannot guarantee any unauthorized collection, access, theft or modification of your  information by hackers, cybercriminals, or other unauthorized third parties. To prevent  unauthorized access to sensitive information, it is important for you to monitor those who have  access to your computer, laptop or any other device through which your account can be  accessed as well as your password. Be sure to sign off when finished while using a shared  computer.</p>
                <p>Please do note that given the nature of internet transactions, Singla Apparels does not take any  responsibility for the transmission of information including Personal Information shared by  you other than as set forth in this Policy. Thus, if you choose to share sensitive data about  yourself using your profile, online chat, or through other interactive areas of the Application,  please be advised that the data would be accessible to anyone who accesses the Application.  Hence, we shall not be liable for the disclosure of any data which is made by you.</p>
                <p>If we become aware that the user has provided us with the above mentioned personal data, we  will delete any Personal Data we have collected, unless we have a legal obligation to keep it,  and terminate the user’s account and/or revert them to the underage experience, as applicable. </p>
                <p>If any user makes explicit/ inappropriate comments regarding other users through the online  chat or through other interactive areas of the Application, such comment would be deemed to  have been made under the supervision of a Parent/Guardian, thus we shall not be taking any  responsibility for the same. We possess the right to review the chats and remove any such  inappropriate/explicit content or any other comment including comments hampering the  goodwill of the Services provided by Singla Apparels. We may also terminate the user’s account,  if it deems fit.</p>
                <h4>MISCELLANEOUS</h4>
                <p>We have made our best efforts in researching, compiling, and providing to you the most  efficient study material and productive teachers all on one platform. However, it is to be noted  that any error including technical, typographical, visual as well as any error in the accents of  the teachers etc will not be our responsibility and we shall not be held liable for the same.</p>
                <p>Moreover, any videos, informational content, animations or pictorial references used for  educational purposes may have been sourced from third party websites. The Website may  contain material that violates the privacy or publicity rights, or infringes the intellectual  property and other proprietary rights, of third parties, or the downloading, copying or use of  which is subject to additional terms and conditions, stated or unstated. Singla Apparels disclaims any responsibility for any harm and/or damages resulting from the use or downloading of  postings of other parties on the website. We are not to be held liable for the infringement of  any Intellectual Property Rights.</p>
                <h4>DATA RETENTION</h4>
                <p>Singla Apparels only retains your personal data for as long as needed to fulfill the purposes for  which it is collected, unless we are required or permitted by law to keep the personal data for  longer, (such as tax, accounting or other legal requirements). No purpose in this notice will  require us to keep your personal information for longer than the period of time in which users  have an account with us. </p>
                <h4>MODIFICATIONS</h4>
                <p>We possess the right to ‘revise/change’ this privacy policy and may do so from time to time to  stay compliant with relevant laws. The updated version will be indicated by an updated  "Revised" date and the updated version will be effective as soon as it is accessible. If we make  material changes to this privacy policy or amend it in the way that Singla Apparels treats User’s  personally identifiable information, we may notify you either by prominently posting a notice  of such changes or by directly sending you a notification. </p>
                <p>Notwithstanding the above, Singla Apparels shall not be required to notify the Users of any  changes made to the privacy policy. Thus, we encourage you to review this privacy policy  frequently to remain updated on how we are protecting your information.</p>
                <h4>TECHNICAL DISPUTES</h4>
                <p>Though our technicians work around the clock to ensure your uninterrupted experience, but in case of any technical error, server issue, bugs or any unforeseen  circumstance, that temporarily restricts the user from availing the Services, we will not be held  liable for the same. </p>
                <h4>PRIVACY RIGHTS </h4>
                <p>You may have the right to request access to the personal information we collect from you,  change that information, or delete it in some circumstances. Singla Apparels will take all  reasonable measures to ensure that the Personal Information is modified and used for rendering  services to You and as otherwise in compliance with laws. To request a copy for your  information, unsubscribe from our email list, request for your data to be reviewed, updated or  deleted, or ask a question about your data privacy, we've made the process simple, email us on <a href="mailto: care@singlaapparels.com">care@singlaapparels.com</a> </p>
                <h4>COMPLAINTS </h4>
                <p>In case of any complaints about this Policy or dissatisfaction regarding any element of your  personal information that we hold, please contact us at - <a href="mailto: care@singlaapparels.com">care@singlaapparels.com</a>. If you are  still unsatisfied with the way we process your personal information and your concern has not been resolved even after your email to us, you may rightfully lodge a complaint with as per the  appropriate laws in India after an expiry of 15 Days since your email. </p>
                <h4>FORCE MAJEURE</h4>
                <p>If either party is in breach of this Agreement or in default of its obligations hereunder because  it fails to perform or observe any or all of the terms of this Agreement resulting directly or  indirectly from causes beyond the reasonable control of such party, such as but not limited to,  acts of God, civil or military authority, acts of the public enemy, war, riots, civil disturbances,  insurrections, accidents, explosions, fires, earthquakes, floods, transportation embargos,  pandemic, endemic, epidemics, acts of government, its agencies or officers, or any other  legitimate cause beyond the reasonable control of the Parties, the party whose performance is  affected or is likely to get effected thereby, shall notify the other party of the occurrence of  such cause, and the time allowed for performance by the affected party will be extended for  the duration of such cause.</p>
                <p>However, if as a consequence of such cause, performance by a party under this Agreement  shall be prevented for a period longer than two (2) months, then the other party shall have the  right to terminate this Agreement after a month’s notice. The terms of termination under this  condition will be with no liabilities or penalties.</p>
                <h4>ARBITRATION</h4>
                <p>This Agreement shall be governed by and construed in accordance with the laws of India. Each  party hereby irrevocably submits to the exclusive jurisdiction of the courts of Uttar Pradesh, for the  adjudication of any dispute hereunder or in connection herewith.</p>
                <ul class="upper-alpha">
                    <li>
                        In case of any disputes, it shall be referred to and finally resolved by Arbitration by  sole arbitrator to be appointed with the company. The Arbitration proceeding shall  be conducted in accordance with the provisions of the Indian Arbitration and  Conciliation Act, 1996 as may be applicable from time to time or any other  enactment replacing it.
                    </li>
                    <li>
                        The Place of Arbitration shall be at Noida (Uttar Pradesh) and the language to be used is English. c. The Arbitration Award shall be final and binding on the Parties and the Parties agree to  be bound thereby and to act accordingly.
                    </li>
                </ul>
                <h4>CONTACT US </h4>
                <p>If you are a parent or guardian and have any concerns regarding the service, wish to review  information collected from your child, or If you have questions or comments about this Privacy  Policy, you may email us at <a href="mailto: care@singlaapparels.com">care@singlaapparels.com</a> or by post to:</p>
                <p>Singla Apparels Private Limited <br />IX/7246 Mahavur Gali <br />Gandhi Nagar, <br />Delhi, Delhi 110031 <br />India.</p>
            </div>
        </div>
    </div>
</section>
      </div>
    );
  }
}


export default (PrivacyPolicy);
