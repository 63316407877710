import React, { Component } from "react";
import { getUuid } from "../../utils/Helpers";
import { connect } from "react-redux";
import { setHeaderVisible, setLoginState,setUser } from "../../redux/reducers/loginStateReducer/actions";
import "./style.scss";
import appviewModel from "../../utils/AppViewModel";
import AppConstants from "../../utils/AppConstants";
import { NotificationManager } from "react-notifications";

class OtpVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
    };
  }

  componentDidMount() {
    this.props.setHeaderVisible(false);
  }

  componentWillUnmount() {
    this.props.setHeaderVisible(true);
  }

  goToAddInfoScreen = (token) => {
    localStorage.setItem("token", token);
    this.props.history.replace("addinfo");
  };
  
  setLoggedInUser = (token, user) => {
    localStorage.setItem("token", token);
    this.props.setLoginState(true);
    this.props.setUser(user);
    this.props.history.replace("/");
  };

  verifyOtp = () => {
    var payload = {
      unique_id: getUuid(),
      app_type: AppConstants.appType,
      country_code: appviewModel.selectedCountry,
      mobile: localStorage.getItem("temp_mobile_number"),
      otp: this.state.otp,
    };
    appviewModel.sendApiCall(
      "/login-signup/otp-validate",
      payload,
      null,
      (response) => {
        switch (response.status) {
          case 0:
            NotificationManager.error(response.msg,'Error');
            break;
          case 1:
            this.setLoggedInUser(response.AccessToken, response.user_info);
            break;
          case 2:
            break;
          case 3:
            this.goToAddInfoScreen(response.AccessToken);
            break;
          case 4:
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  render() {
    return (
      <section className="full-bg-img otp-style-bg _fix_bg">
        <div className="middle-form-outer">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="middle-form-inner">
                  <div className="otp-main">
                    <div className="top-logo">
                      <img src="img/verification-otp.png" alt="img" />
                    </div>
                    <div className="top-info">
                      <h4>Verify Mobile Number</h4>
                      <p>Enter 6 digit verification code sent to your number</p>
                    </div>
                    <form>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="------"
                          value={this.state.otp}
                          onChange={(e) =>
                            this.setState({ otp: e.target.value })
                          }
                        />
                      </div>
                      <div className="other-links">
                        <a href="/#">Change Number</a>
                        <a href="/#">Resend OTP</a>
                      </div>
                      <div
                        className="submit-btn"
                        onClick={() => this.verifyOtp()}
                      >
                        <p className="button-60000f">VERIFY CODE</p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHeaderVisible: (data) => {
      dispatch(setHeaderVisible(data));
    },
    setLoginState: (data) => {
      dispatch(setLoginState(data));
    },
    setUser: (data) => {
      dispatch(setUser(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpVerify);
