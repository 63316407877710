import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  setLoginState,
  setUser,
} from "../../redux/reducers/loginStateReducer/actions";
import AppConstants from "../../utils/AppConstants";
import appviewModel from "../../utils/AppViewModel";
import "./style.scss";

class Styles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ isLoading: true });
    var payload = {
      page_size: 20,
      page_index: 0,
      subcategory_id: this.props.match.params.id,
    };
    appviewModel.sendApiCall(
      "/styles",
      payload,
      null,
      (response) => {
        console.log(response);
        this.setState({ data: response.data, isLoading: false });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  render() {
    if (this.state.isLoading) return null;
    return (
      <div className="style_screen">
        <section class="product-filter-outer">
          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <div class="product-filter-list">
                  <div class="filter-items">
                    <div class="offers-only">
                      <button
                        type="button"
                        class="btn btn-toggle active"
                        data-toggle="button"
                        aria-pressed="true"
                        autocomplete="off"
                      >
                        <div class="handle"></div>
                      </button>
                      Offers Only
                    </div>
                  </div>
                  <div class="filter-items">
                    <div class="price-filter">
                      <span class="filter-icon">
                        <i class="fas fa-rupee-sign"></i>
                      </span>{" "}
                      999 - 1299
                    </div>
                  </div>
                  <div class="filter-items">
                    <div class="short-filter">
                      <span class="filter-icon">
                        <i class="fas fa-sliders-h"></i>
                      </span>{" "}
                      Sort
                    </div>
                  </div>
                  <div class="filter-items">
                    <div class="other-filter">
                      <span class="filter-icon">
                        <i class="fas fa-filter"></i>
                      </span>{" "}
                      Filter
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="sort-main">
            <div class="container">
              <div class="row">
                <div class="col-md-3 col-sm-6">
                  <div class="sort-list-inner">
                    <div class="sort-icon">
                      <img src="img/sortArrowDown.png" alt="icon" />
                    </div>
                    <div class="sort-text">
                      Price <br /> Low - High
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="sort-list-inner">
                    <div class="sort-icon">
                      <img src="img/sortArrowUp.png" alt="icon" />
                    </div>
                    <div class="sort-text">
                      Price <br /> High - Low
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="sort-list-inner">
                    <div class="sort-icon">
                      <img src="img/percentage.png" alt="icon" />
                    </div>
                    <div class="sort-text">Margin</div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="sort-list-inner">
                    <div class="sort-icon">
                      <img src="img/new.png" alt="icon" />
                    </div>
                    <div class="sort-text">What's New</div>
                  </div>
                </div>
              </div>
              <div class="sort-overlay-close">
                <img src="img/chatCross.png" alt="cross" />
              </div>
            </div>
          </div>
        </section>

        <section class="sub-category-outer">
          <div class="container">
            <div class="row">
              {this.state.data.map((item, index) => {
                return (
                  <div class="col-md-4" key={index}>
                    <div class="sub-category-list product-list">
                      <div class="sub-cat-img">
                        <Link to={"/colors/"+item.id}>
                          <img src={AppConstants.baseUrl + item.image} alt="kurta" class="image"/>
                        </Link>
                      </div>
                      <div class="product-info">
                        <div class="product-title">
                          <Link to={"/colors/"+item.id}>KURTA SET : Neck Embroidery</Link>
                        </div>
                        <div class="article-section">Article : 01</div>
                        <div class="size-color">
                          <div class="size-part">
                            <ul>
                              <li>
                                <span>M</span>,
                              </li>
                              <li>
                                <span>L</span>,
                              </li>
                              <li>
                                <span>XL</span>,
                              </li>
                              <li>
                                <span>3XL</span>,
                              </li>
                              <li>
                                <span>XXL</span>
                              </li>
                            </ul>
                          </div>
                          <div class="color-part">
                            <ul>
                              <li>
                                <span class="col-60000f"></span>
                              </li>
                              <li>
                                <span class="col-92ccff"></span>
                              </li>
                              <li>
                                <span class="col-fe8628"></span>
                              </li>
                              <li>
                                <i>+20 more</i>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="discount-price">
                          <div class="discount-precent">
                            <span class="margin">XX% Margin</span>
                          </div>
                          <div class="mrp-price">
                            <span>MRP:</span>
                            <span class="price"> ₹ 1999/-</span>
                          </div>
                        </div>
                      </div>
                      <div class="wish-cart-icon">
                        <ul>
                          <li>
                            <i class="fas fa-shopping-cart"></i>
                          </li>
                          <li>
                            <i class="fas fa-heart"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateTpProps = (state) => {
  return {
    login_state: state.login_state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginState: (data) => {
      dispatch(setLoginState(data));
    },
    setUser: (data) => {
      dispatch(setUser(data));
    },
  };
};

export default connect(mapStateTpProps, mapDispatchToProps)(Styles);
