import { SET_LOGIN_STATE, SET_HEADER_VISIBLE, SET_USER } from "./actionTypes";

const initialState = {
  isLogin: false,
  isHeaderVisible: true,
  user: {},
};

var loginStateReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_LOGIN_STATE:
      state.isLogin = action.payload;
      return { ...state };
    case SET_HEADER_VISIBLE:
      state.isHeaderVisible = action.payload;
      return { ...state };
    case SET_USER:
      state.user = action.payload;
      return { ...state };
    default:
      return state;
  }
};

export default loginStateReducer;
