import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./style.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume, faUserCircle } from '@fortawesome/free-solid-svg-icons';

class Header extends Component {
  render() {
    if(!this.props.login_state.isHeaderVisible){
      return null;
    }
    return (
      <header>
        <div className="top_navigation">
          <div className="container">
            <div className="custom-nav-bar">
              <nav className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand" to="/">
                  <img src="img/singla-logo.png" alt="Singla Logo" />
                </Link>
                <button type="button" className="menu-btn">
                  <div className="burger"></div>
                </button>
                <div className="navbar-collapse collapse show mobile_menu menu-off">
                  <ul className="navbar-nav ml-md-auto d-md-flex">
                    <li className="nav-item active">
                      <a className="nav-link" href="tel:9350 350 350">
                      <FontAwesomeIcon icon={faPhoneVolume} /> 9350 350 350
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/">
                        About Us
                      </Link>
                    </li>
                    {!this.props.login_state.isLogin && (
                      <li className="nav-item">
                        <Link className="nav-link" to="/login">
                        <FontAwesomeIcon icon={faUserCircle} /> Login
                        </Link>
                      </li>
                    )}
                    {this.props.login_state.isLogin && (
                      <li className="nav-item">
                        <Link className="nav-link" to="/profile">
                        <FontAwesomeIcon icon={faUserCircle} /> {this.props.login_state.user.CompanyName}
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login_state: state.login_state,
  };
};

export default connect(mapStateToProps)(Header);
