import React from "react";
import { Switch } from "react-router-dom";
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Home from "../screens/Home";
import Login from "../screens/Login";
import OtpVerify from "../screens/OtpVerify";
import AddAccountInfo from "../screens/AddAccountInfo";
import Profile from "../screens/Profile";
import SubCategories from "../screens/SubCategories";
import Styles from "../screens/Styles";
import Colors from "../screens/Colors";
import PrivacyPolicy from "../screens/Policy/PrivacyPolicy";
import TermsAndCondition from "../screens/Policy/TermsAndCondition";

const Routes=()=> {
  return (
    <Switch>
        <PublicRoute exact path="/" component={Home} />
        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute exact path="/verification" component={OtpVerify} />
        <PublicRoute exact path="/addinfo" component={AddAccountInfo} />
        <PublicRoute exact path="/b2bprivacypolicy" component={PrivacyPolicy} />
        <PublicRoute exact path="/b2btermsandconditions" component={TermsAndCondition} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/subcategories/:id" component={SubCategories} />
        <PrivateRoute exact path="/styles/:id" component={Styles} />
        <PrivateRoute exact path="/colors/:id" component={Colors} />
      </Switch>
  );
}

export default Routes;